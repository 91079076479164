@import '~@fortawesome/fontawesome-free/css/all.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import '~antd/dist/antd.css';
@import '~react-vis/dist/style.css';

html,
body,
#root {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    width: 100%;
    height: 100%;
}
.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
}

.ant-layout {
    width: 100%;
    min-height: 100%;
}

.ant-layout .site-layout-background {
    background: #fff;
}
.ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ant-message .anticon {
    top: -3px;
}

.ant-steps-item-icon .ant-steps-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.ant-statistic .ant-statistic-title {
    text-align: center;
}

label.ant-checkbox-wrapper {
    pointer-events: none;
}
label.ant-checkbox-wrapper span.ant-checkbox {
    pointer-events: all;
}

.spin-message {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    top: -170px;
    position: absolute;
}

.m-l-10 {
    margin-left: 10px;
}


